import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import AddEditAdminUserDialog from "../components/add_edit_admin_user_dialog";
import { InputText } from "primereact/inputtext";
import { getAdminUsers, getUserRoles } from "../../../../../redux/slices/admin_user_slice";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import { axiosApi } from "../../../constants/axios_api";
import appUrl from "../../../constants/appUrl";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";

export default function AdminUsersScreen(props) {
    const user = localStorage.getItem("user");
    const permissions = props?.permissions;
    const parseUser = JSON.parse(user);

    const myData = parseUser?.permissions?.find((item) => item?.path == "/api/admin/users");

    const [search, setSearch] = useState("");
    const [pageNumber, setPageNumber] = useState("");
    const adminUsersSelector = useSelector((state) => state.getUser);
    const { roles = [], adminUsers, loading, count, editSuccess, addSuccess } = adminUsersSelector;

    const dispatch = useDispatch();

    const [showAddUserDialog, setAddUserDialog] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [corporateList, setCorporateList] = useState();
    const [updatedRoles, setUpdatedRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const getallCorporates = async () => {
        const response = await axiosApi.get(`${appUrl.baseUrl}api/corporate/corporateLov`);
        setCorporateList(response?.data?.data);
    };
    const getCorporatesRole = async () => {
        const response = await axiosApi.get(`${appUrl.baseUrl}api/web/role/corporateAll`);
        setUpdatedRoles(response?.data?.data);
    };

    useEffect(() => {
        getallCorporates();
        // getCorporatesRole()
    }, []);

    const formik = useFormik({
        initialValues: {
            corporate: "",
        },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const dialogFuncMap = {
        showAddUserDialog: setAddUserDialog,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    const rowClass = (data) => {
        if (data?.status === true) {
            return {
                "bg-success": true, // Green background for active
            };
        } else if (data?.status === false) {
            return {
                "bg-danger": true, // Red background for inactive
            };
        } else {
            return {}; // No class if no status
        }
    };
    const actionsBodyTemplate = (rowData) => {
        return (
            <div>
                {(myData?.permissions?.edit || parseUser?.role?.role === "Super_Admin") && (
                    <Button
                        icon="pi pi-user-edit"
                        className="p-button-rounded p-button-success"
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("showAddUserDialog");
                        }}
                        aria-label="Edit"
                    />
                )}
            </div>
        );
    };

    const corporate = formik.values.corporate;
    useEffect(() => {
        // Check if `corporate` is selected; if so, use it in the API call
        if (corporate) {
            dispatch(getAdminUsers({ pageNumber:0, search, perPageRecords: rows, role: selectedRole, status: selectedStatus, corporate }));
        } else {
            dispatch(getAdminUsers({ pageNumber: 0, search, perPageRecords: rows, role: selectedRole, status: selectedStatus }));
        }
    }, [search, editSuccess, addSuccess, selectedRole, selectedStatus, corporate]);

    // Custom template for Created At column
    const createdAtTemplate = (rowData) => {
        const formattedDate = moment(rowData?.createdAt).format("DD/MM/YYYY"); // Only date format
        const fullDate = moment(rowData?.createdAt).format("MMMM Do YYYY, h:mm a"); // Full date with time
        return (
            <span className="date-tooltip-container">
                <span className="date-tooltip">{formattedDate}</span>
                <span className="date-full-tooltip">{fullDate}</span>
            </span>
        );
    };

    // Custom template for Updated At column (Similar to Created At)
    const updatedAtTemplate = (rowData) => {
        const formattedDate = moment(rowData?.updatedAt).format("DD/MM/YYYY"); // Only date format
        const fullDate = moment(rowData?.updatedAt).format("MMMM Do YYYY, h:mm a"); // Full date with time

        return (
            <span className="date-tooltip-container">
                <span className="date-tooltip">{formattedDate}</span>
                <span className="date-full-tooltip">{fullDate}</span>
            </span>
        );
    };

    // Custom header for Created At column
    const dateHeaderTemplate = () => (
        <>
            Created At
            <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>DD/MM/YYYY</div>
        </>
    );

    // Custom header for Updated At column
    const updatedDateHeaderTemplate = () => (
        <>
            Updated At
            <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>DD/MM/YYYY</div>
        </>
    );

    const onPageChange = (event) => {
        setPageNumber(event.first / event.rows);
        setFirst(event.first);
        setRows(event.rows);
        dispatch(getAdminUsers({ pageNumber: event.first / event.rows, search, perPageRecords: event.rows, role: selectedRole, status: selectedStatus }));
    };

    const clearFilters = () => {
        // Clear formik values
        formik.setFieldValue("corporate", "");
        setSelectedRole("");
        setSearch("");
        setSelectedStatus("");
        setCorporateList(corporateList);
        setPageNumber(0);
        setFirst(0);
        dispatch(
            getAdminUsers({
                pageNumber: 0,
                search: "", // Updated this line
                perPageRecords: rows,
                gender: "",
                loginDevice: "",
                status: "",
                corporate: "",
            })
        );

        setUpdatedRoles([]);
    };

    useEffect(() => {
        const corporate = formik.values.corporate;
        if (corporate) {
            getCorporatesRole();
        } else {
            dispatch(getUserRoles()); // Otherwise, fetch default roles
        }
    }, [formik.values.corporate]);

    return (
        <>
            <Dialog header={editUser == null ? "Add User" : "Update User"} visible={showAddUserDialog} onHide={() => onHide("showAddUserDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }} className="Modal_class">
                <AddEditAdminUserDialog editUser={editUser} onHide={() => onHide("showAddUserDialog")} />
            </Dialog>
            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Admin users</h4>
                    </div>
                    <div className="col md:col-11">
                        <div className="mb-9 text-right">
                            <Button style={{ verticalAlign: "top", fontSize: "14px" }} label="Clear Filters" icon="pi pi-times" className=" mr-1 text-left" onClick={clearFilters} />
                            <Dropdown className="mr-1 text-left" options={corporateList} optionLabel="name" optionValue="_id" id="corporate" name="corporate" value={formik.values.corporate || ""} onChange={formik.handleChange} style={{ width: "140px" }} placeholder="Corporate" />
                            <Dropdown
                                style={{ width: "100px" }}
                                className="mr-1 text-left"
                                options={updatedRoles?.length ? updatedRoles : [{ _id: "" }, ...roles]}
                                optionLabel="role"
                                optionValue="_id"
                                value={selectedRole}
                                onChange={(e) => {
                                    setSelectedRole(e.value);
                                }}
                                placeholder="Role"
                            />
                            <Dropdown
                                style={{ width: "130px" }}
                                className="mr-1 text-left"
                                options={[
                                    {
                                        text: "Active",
                                        value: 1,
                                    },
                                    {
                                        text: "Inactive",
                                        value: 0,
                                    },
                                ]}
                                optionLabel="text"
                                optionValue="value"
                                value={selectedStatus}
                                onChange={(e) => {
                                    setSelectedStatus(e.value);
                                }}
                                placeholder="Status"
                            />
                            <span className="p-input-icon-right mr-1">
                                <InputText style={{ width: "140px" }} className="mbl_view" value={search} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                            {(myData?.permissions?.edit || parseUser?.role?.role === "Super_Admin") && (
                                <Button
                                    style={{ width: "120px", fontSize: "14px" }}
                                    label="Add new"
                                    className="p-button-success p-button-rounded p-button-sm custom___width"
                                    onClick={() => {
                                        setEditUser(null);
                                        onClick("showAddUserDialog");
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <DataTable
                    value={adminUsers}
                    rowClassName={rowClass}
                    className="p-datatable-gridlines"
                    showGridlines
                    rows={50}
                    dataKey="id"
                    filterDisplay="menu"
                    loading={loading}
                    responsiveLayout="scroll"
                    emptyMessage="No record found."
                    globalFilterFields={["userName", "email", "mobile", "role.role"]}
                >
                    <Column className="maker_colm" field="userName" header="Username" style={{ minWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} />
                    <Column className="maker_colm" field="email" header="Email" style={{ minWidth: "5rem", maxWidth: "100%", overflowX: "auto" }} />
                    <Column className="maker_colm" field="mobile" header="Mobile" style={{ minWidth: "10rem" }} />
                    <Column className="maker_colm" field="role.role" header="Role" style={{ minWidth: "10rem" }} />
                    {/* <Column body={createdAtTemplate} header="Created At" style={{ minWidth: "10rem" }} />
                    <Column body={updatedAtTemplate} header="Updated At" style={{ minWidth: "10rem" }} /> */}
                    <Column className="maker_colm" style={{ minWidth: "8rem" }} body={createdAtTemplate} header={dateHeaderTemplate} />
                    <Column className="maker_colm" style={{ minWidth: "8rem" }} body={updatedAtTemplate} header={updatedDateHeaderTemplate} />
                    {/* <Column field="status" header="Status" style={{ minWidth: "4rem" }} body={statusBodyTemplate} /> */}
                    <Column className="text-center Action_col" header="Actions" style={{ minWidth: "4rem" }} body={actionsBodyTemplate} />
                </DataTable>
                <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
            </div>
        </>
    );
}
