import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
// import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import { getDriversList, updateDriverDocumentStatus } from "../../../../../redux/actions/drivers_actions";
// import { UPDATE_DRIVER_DOCUMENTS_STATUS_RESET } from "../../../../../redux/constants/drivers_constants";
import CustomInputField from "../../../components/custom_input_filed";
import appUrl from "../../../constants/appUrl";
//import { getDriversList, updateDriverDocumentStatus } from "../../../../../redux/actions/drivers_actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
//import { UPDATE_DRIVER_DOCUMENTS_STATUS_RESET } from "../../../../../redux/constants/drivers_constants";
import { Image } from "primereact/image";
import { getDriversList, resetUpdateDrivers, updateDriverDocumentStatus } from "../../../../../redux/slices/drivers_slice";
import { getDocumentsVerifications } from "../../../../../redux/slices/documentVerificationSlice";

export default function VerificationDialog({ user, userId, onHide, document, permissions, flag, pageNumber, getDocumentsVerifications }) {
    // redux
    const dispatch = useDispatch();

    const updateDriverDocumentsStatusReducer = useSelector((state) => state.driversList);
    const { updateSuccess: success, updateError: error, updateLoading: loading } = updateDriverDocumentsStatusReducer;

    //use effects
    useEffect(() => {
        if (success !== undefined) {
            if (success === true) {
                // toast.success("Status updated");
                onHide();
                dispatch(getDocumentsVerifications({ pageNumber: 0, flag, perPageRecords: 10 }));
                // dispatch(getDocumentsVerifications({ flag, pageNumber }));
            } else {
                toast.warn(error);
            }
        }
        return () => {
            //dispatch({ type: UPDATE_DRIVER_DOCUMENTS_STATUS_RESET });
            dispatch(resetUpdateDrivers());
        };
    }, [success, error]);

    //status

    const statusList = [
        {
            name: "Verify",
            value: "verified",
        },
        {
            name: "Reject",
            value: "rejected",
        },
    ];

    //forms

    const validationSchema = Yup.object().shape({
        comment: Yup.string().required("Comment is required"),
        // status: Yup.string().required("Status is required"),
        status: Yup.string()
            .test(
                "not-pending",
                "Status is required",
                (value) => value && value !== "pending"
            )
            .required("Status is required"),
    });


    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            status: "pending",
            comment: "",
        },

        onSubmit: async (data) => {
            data["userId"] = userId;
            const body = {
                userId: userId,
                type: document.type,
                status: data.status,
                comment: data.comment,
            };

            dispatch(updateDriverDocumentStatus(body));
        },
    });

    useEffect(() => {
        if (document) {
            formik.setFieldValue("status", document["status"]);
            formik.setFieldValue("comment", document["comment"]);
            formik.setFieldValue("type", document["type"]);
        }
    }, [document]);

    return (
        <>
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid">
                        <div className="col-12 p-fluid">
                            <div className="flex mb-2 w-8 justify-content-between pr-3 ">
                                <div className="flex flex-column justify-content-center">
                                    <label className="mb-5">Name: {`${user?.firstName} ${user?.lastName}`}</label>
                                    <label className="mb-5">CNIC: {user?.cnic}</label>
                                    <label className="mb-5">Gender: {user?.gender}</label>
                                    <label className="mb-5">DOB: {user?.dob ? user?.dob : ""}</label>
                                </div>
                                <div>
                                    <Image width="150px" preview template="Preview" src={`${appUrl.fileBaseUrl}${document?.frontImage}`} alt="" className="mr-2" />
                                    {/* {//console.log("img",`${appUrl.fileBaseUrl}${document?.frontImage}`)} */}
                                    <Image width="150px" preview template="Preview" src={`${appUrl.fileBaseUrl}${document?.backImage}`} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 p-fluid">
                            <CustomInputField iden="status" formik={formik} type="dropdown" options={statusList} optionLabel="name" optionValue="value" placeHolder="Please select" />
                        </div>
                        <div className="col-12 md:col-6 p-fluid">
                            <CustomInputField iden="comment" formik={formik} type="textArea" />
                        </div>
                        <div className="col-12 p-fluid">
                            <div className="button_class-modal">{permissions?.edit && <Button type="submit" loading={loading} label="Submit" className="p-button-success btn__default_modal p-button-rounded" />} </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
