import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Button } from "primereact/button";
import CustomInputField from "../../../components/custom_input_filed";
import { addTutorial, editTutorial, getTutorialsList, resetAddEditTutorials } from "../../../../../redux/slices/tutorials_slice";
import { toast } from "react-toastify";
import { getTutorialsCategoryList } from "../../../../../redux/slices/tutorials_category_slice";

export default function AddEditTutorial({ editData, onHide, permissions }) {
    const dispatch = useDispatch();
    const re = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

    //redux
    const tutorialCategoryReducer = useSelector((state) => state.tutorialCategoryList);
    const { data } = tutorialCategoryReducer;
    const { data:list, addLoading, editLoading, addSuccess, editSuccess, addError, editError } = useSelector((state) => state.tutorialList);
    //hooks
    // const [categoryList, setCategoryList] = useState("");
    // useEffect(() => {
    //     if (list === undefined) {
    //         dispatch(getTutorialsCategoryList(categoryList));
    //     }
    // }, [categoryList, dispatch]);
    const validationSchema = Yup.object().shape({
        category: Yup.string().required("Tutorial category is required"),
        title: Yup.string().required("This field is required"),
        videoLink: Yup.string().matches(re, "Please add valid url").required("This field is required."),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        enableReinitialize: true,
        initialValues: {
            videoLink: "",
            title: "",
            category: "",
        },

        onSubmit: async (data) => {
            // let body = {
            //     videoLink: data.videoLink,
            //     title: data.title,
            //     category: data.category,
            // };
            if (editData === null) {
                dispatch(addTutorial(data));
            } else {
                data["tutorialId"] = editData._id;
                dispatch(editTutorial(data));
                ////console.log((data))
            }
        },
    });
    useEffect(() => {
        if (data === undefined) {
            dispatch(getTutorialsCategoryList());
        }
    }, []);
    // useEffect(() => {
    //     if (tutorialList === undefined) {
    //         dispatch(getTutorialsList());
    //     }
    // }, []);
    useEffect(() => {
        if (editData !== null) {
            loadValues();
        }
        //dispatch(getFaqsCategoryList());
    }, []);

    const loadValues = () => {
        formik.setFieldValue("videoLink", editData.videoLink);
        formik.setFieldValue("title", editData.title);
        formik.setFieldValue("category", editData.category._id);
        //setCategoryList(editData.CategoryList)
    };
    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Tutorial successfully added");
                onHide();
                dispatch(getTutorialsList());
            } else {
                toast.error(addError);
            }
        }
        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success("Tutorial successfully updated");
                onHide();
                dispatch(getTutorialsList());
            } else {
                toast.error(editError);
            }
        }
        return () => {
            dispatch(resetAddEditTutorials());
        };
    }, [addSuccess, addError, editSuccess, editError]);
    // const name= list.map((item) => (
    //    item?.category?.name
    // ))

    const name = list.map((item) => ({
        name: item?.category?.name,
        _id: item?.category?._id,
    }));

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className=" p-fluid grid">
                        <div className="col-12">
                            <CustomInputField type="dropdown" iden="category" label="Category" name="category" options={data} formik={formik} optionLabel="name" optionValue="_id" placeHolder="Please Select" />
                            {/* <div style={{marginBottom:"0.5rem"}}>Category</div>
                            <Dropdown className="text-left" name="name" options={list} optionLabel="name" optionValue="_id" value={categoryList} onChange={(e) => setCategoryList(e.value)} placeholder="Select a Category " formik={formik} /> */}
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="title" label="Title" formik={formik} type="text" />
                        </div>
                        <div className="col-12">
                            <CustomInputField iden="videoLink" label="Redirect URL" formik={formik} type="text" />
                        </div>
                        <div className="col-12">
                            <div className="button_class-modal">
                                {/* {permissions?.add && editData == null &&  */}
                                <Button loading={editData == null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />
                                {/* }{" "} */}
                                {permissions?.edit && editData && <Button loading={editData == null ? addLoading : editLoading} type="submit" label={editData === null ? "Add" : "Update"} className="p-button-success btn__default_modal p-button-rounded" />}{" "}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
}
