import decryptData from '../services/decryptData';
import encryptData from '../services/encryptData';
import appUrl from './appUrl';

const axios = require('axios');
const axiosApi = axios.create();

// Request interceptor for API calls
axiosApi.interceptors.request.use(
  async config => {
    const access_token = localStorage.getItem('accessToken');
    if (access_token) {
      config.headers = {
        'Authorization': `Bearer ${access_token}`,
      };
    }
    config.data = encryptData(config?.data);
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApi.interceptors.response.use(
  response => {
    if (response?.data?.cipher) {
      response.data = decryptData(response.data.cipher);
    }
    return response;
  },
  async function (error) {
    if (error?.response?.data?.cipher) {
      error.response.data = decryptData(error?.response?.data?.cipher);
    }
    const originalRequest = error?.config;

    // Check if the error is due to an expired token
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;

      const access_token = await refreshAccessToken();
      if (access_token) {
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        localStorage.setItem('accessToken', access_token);
        return axiosApi(originalRequest);
      } else {
        // If refresh fails, log out the user
        console.error('Token refresh failed. Redirecting to login.');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

// Function to refresh access token
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    try {
      const payload = { refreshToken };
      const payloadData = encryptData({ refreshToken });

      let { data } = await axios.post(appUrl.baseUrl + appUrl.refreshToken, payloadData);

      // Decrypt the response data and extract the new access token
      data = decryptData(data?.cipher);
      const newAccessToken = data?.data?.accessToken;

      if (!newAccessToken) {
        throw new Error('Invalid response: Missing accessToken');
      }

      return newAccessToken;
    } catch (error) {
      console.warn('Refresh token API call failed:', error?.response || error);
      return null;
    }
  } else {
    console.warn('No refresh token found in localStorage');
    return null;
  }
};

export { axiosApi };
