import React, { useEffect, useRef, useState } from "react";
import CustomPageHeader from "../../components/custom_page_header";
import CustomInputField from "../../components/custom_input_filed";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getAdminUserById, updateProfileImage } from "../../../../redux/slices/admin_user_slice";
import appUrl from "../../constants/appUrl";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom";


const Profile = () => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const fileInputRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const adminUsersSelector = useSelector((state) => state.getUser);
    const { adminUser } = adminUsersSelector;
    const handleImageClick = () => {
        setIsEditing(true);
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setProfileImage(e.target.result);
        };

        reader.readAsDataURL(file);
    };

    const handleImageInputChange = (event) => {
        // Handle the file input change here
        const selectedFile = event.target.files[0];
        setSelectedImage(URL.createObjectURL(selectedFile));
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            setProfileImage(e.target.result);
        };

        reader.readAsDataURL(file); // You can add your logic to upload the new image
    };
    useEffect(() => {
        if (profileImage) {
            dispatch(updateProfileImage({ userId: adminUser?._id, profileImage }));
        }
    }, [profileImage]);
    useEffect(() => {
        let user = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user);
        }
        if (user) dispatch(getAdminUserById(user._id));
    }, []);
    const formik = useFormik({
        // validationSchema: validationSchema,
        initialValues: {
            email: adminUser ? adminUser.email : "",
            mobile: adminUser ? adminUser.mobile : "",
            role: adminUser?.role ? adminUser.role.role : "",
            status: adminUser && adminUser?.status ? "Active" : "Inactive",
            userName: adminUser ? adminUser.userName : "",
        },

        onSubmit: async (data) => {},
    });
    useEffect(() => {
        if (adminUser) {
            formik.setValues({
                email: adminUser.email,
                mobile: adminUser.mobile,
                role: adminUser.role ? adminUser.role.role : "",
                status: adminUser && adminUser?.status ? "Active" : "Inactive",
                userName: adminUser.userName,
            });
        }
    }, [adminUser]);
    return (
        <div className="card">
            <form>
                <div className="pb-5">
                    <div className="grid">
                        <div className="col-12 md:col-7">
                            <CustomPageHeader title={"Admin Profile"} />
                        </div>
                    </div>
                </div>

                <div className="grid p-fluid">
                    {" "}
                    <div className=" col-4">
                        <div className="col-12 image-container">
                            <div className={`image-mask ${isEditing ? "editable" : ""}`} onClick={handleImageClick}>
                                {selectedImage ? <img src={selectedImage} alt="Profile Image" /> : <img src={adminUser?.profileImage ? appUrl.baseUrl + adminUser.profileImage : appUrl.baseUrl + "images/defaultprofile.jpg"} alt="Profile Image" />}{" "}
                            </div>
                            <input type="file" accept="image/*" hidden ref={fileInputRef} onChange={handleImageInputChange} />
                        </div>
                        <div className="flex flex-column align-items-center">{/* Content */}</div>

                        <div className="flex flex-column align-items-center"></div>
                    </div>
                    <div className=" col-8">
                        <div className=" col-12 md:col-12">
                            <CustomInputField iden="userName" label={"User Name"} formik={formik} type="text" disabled className="field" />
                        </div>
                        <div className=" col-12 md:col-12">
                            <CustomInputField iden="email" label={"Email"} formik={formik} type="email" disabled className="field" />
                        </div>
                        <div className=" col-12 md:col-12">
                            <CustomInputField iden="mobile" formik={formik} label="Mobile Number" type="text" disabled className="field" />
                        </div>
                        <div className=" col-12 md:col-12">
                            <CustomInputField iden="role" formik={formik} label="Role" type="text" disabled className="field" />
                        </div>
                        <div className=" col-12 md:col-12">
                            <CustomInputField iden="status" formik={formik} label="Status" type="text" disabled className="field" />
                        </div>
                        <div className=" col-12 md:col-12 text-center">
                            <span>
                                <Link to="/resetpassword">
                                    <Button className="bg-primary">Reset Password</Button>
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Profile;
