import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddEditSubModule from "../components/add_edit_sub_module";
import { useDispatch, useSelector } from "react-redux";
import { getModulesList } from "../../../../../redux/slices/modules_slice";
import AddEditModule from "../components/add_edit_module";
import StatusChange from "../components/active_module_status_change";
import SubModuleStatusChange from "../components/active_subModule_status_change";

const Module = ({ permissions }) => {
    const dispatch = useDispatch();
    const [expandedRows, setExpandedRows] = useState(null);
    const [editSubModule, setEditSubModule] = useState(null);
    const [editModule, setEditModule] = useState(null);

    const [showAddEditModuleDialog, setAddEditModuleDialog] = useState(false);
    const [showAddEditSubModuleDialog, setAddEditSubModuleDialog] = useState(false);
    const [showAddEditStatusModuleDialog, setShowAddEditStatusModuleDialog] = useState(false);
    const [showAddEditStatusSubModuleDialog, setShowAddEditStatusSubModuleDialog] = useState(false);
    const modulesListReducer = useSelector((state) => state.modulesList);
    const { data = [], addSubSuccess, addSuccess, editSuccess, activeSuccess, activeSubModuleSuccess } = modulesListReducer;

    const allowExpansion = (rowData) => {
        return <>{rowData.length > 0}</>;
    };

    const nameBodyTemp = (rowData) => {
        const capital = rowData?.name?.charAt(0)?.toUpperCase() + rowData?.name?.slice(1)?.toLowerCase();
        return <div>{capital}</div>;
    };
    const iconBodyTemp = (rowData) => {
        return (
            <>
                <i className={rowData.icon}></i>
            </>
        );
    };
    // const statusBodyTemplate = (rowData) => {
    //     // return <span className={rowData.active ? "green" : "red"}>{rowData.active ? "Active" : "Inactive"}</span>;
    //     return <div className={rowData.active ? "bg_green" : "bg_red"}>{rowData.active ? "Active" : "Inactive"}</div>;
    // };

    const rowClass = (data) => {
        if (data?.active === true) {
            return {
                "bg-success": true, // Green background for active
            };
        } else if (data?.active === false) {
            return {
                "bg-danger": true, // Red background for inactive
            };
        } else {
            return {}; // No class if no status
        }
    };

    const subModuleNameBodyTemp = (rowData) => {
        const capital = rowData.name.charAt(0).toUpperCase() + rowData.name.slice(1).toLowerCase();
        return <div>{capital}</div>;
    };

    const subModuleIconBodyTemp = (rowData) => {
        return (
            <>
                <i className={rowData.icon}></i>
            </>
        );
    };
    // const subModuleStatusBodyTemplate = (rowData) => {
    //     // return <span className={rowData.active ? "green" : "red"}>{rowData.active ? "Active" : "Inactive"}</span>;
    //     return <div className={rowData.active ? "bg_green" : "bg_red"}>{rowData.active ? "Active" : "Inactive"}</div>;
    // };

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const dialogFuncMap = {
        showAddEditModuleDialog: setAddEditModuleDialog,
        showAddEditSubModuleDialog: setAddEditSubModuleDialog,
        showAddEditStatusModuleDialog: setShowAddEditStatusModuleDialog,
        showAddEditStatusSubModuleDialog: setShowAddEditStatusSubModuleDialog,
    };
    const actionTemplate = (rowData) => {
        return (
            <>
                <div className="Edit_Icon">
                    {permissions?.add && (
                        <Button
                            tooltip="Add Sub Module"
                            icon="pi pi-plus"
                            tooltipOptions={{ position: "top" }}
                            className="edit m-2 p-button-rounded p-button-success"
                            onClick={() => {
                                setEditSubModule(null);
                                onClick("showAddEditSubModuleDialog");
                            }}
                        />
                    )}
                    {permissions?.edit && (
                        <Button
                            tooltip="edit"
                            icon="pi pi-pencil"
                            tooltipOptions={{ position: "top" }}
                            aria-label="Submit"
                            onClick={() => {
                                setEditModule(rowData);
                                onClick("showAddEditModuleDialog");
                            }}
                            className="edit m-2 p-button-rounded p-button-success"
                        />
                    )}
                    {permissions?.edit && (
                        <Button
                            onClick={() => {
                                setEditModule(rowData);
                                onClick("showAddEditStatusModuleDialog");
                            }}
                            className="edit m-2 p-button-rounded p-button-success"
                            tooltip="Change Status"
                            icon="pi pi-sliders-h"
                            tooltipOptions={{ position: "top" }}
                        />
                    )}
                </div>
            </>
        );
    };

    const subModuleActionTemp = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.edit && (
                    <Button
                        tooltip="edit"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        aria-label="Submit"
                        onClick={() => {
                            setEditSubModule(rowData);
                            onClick("showAddEditSubModuleDialog");
                        }}
                        className="edit m-2 p-button-rounded p-button-success"
                    />
                )}
                {permissions?.edit && (
                    <Button
                        onClick={() => {
                            setEditSubModule(rowData);
                            onClick("showAddEditStatusSubModuleDialog");
                        }}
                        className="edit m-2 p-button-rounded p-button-success"
                        tooltip="Change Status"
                        icon="pi pi-sliders-h"
                        tooltipOptions={{ position: "top" }}
                    />
                )}
            </div>
        );
    };
    const expandedTableTemp = (rowData) => {
        return (
            <DataTable rowClassName={rowClass} showGridlines={true} value={rowData.submodule}>
                <Column className="maker_colm" style={{ minWidth: "22rem" }} field="name" body={subModuleNameBodyTemp} header="Name" />
                <Column className="maker_colm" style={{ minWidth: "22rem" }} body={subModuleIconBodyTemp} header="Icon" />
                {/* <Column className="maker_colm" body={subModuleStatusBodyTemplate} header="Status" /> */}
                <Column className="maker_colm" body={subModuleActionTemp} header="Action" />
            </DataTable>
        );
    };
    useEffect(() => {
        dispatch(getModulesList());
    }, [addSubSuccess, addSuccess, editSuccess, activeSuccess, activeSubModuleSuccess]);
    return (
        <>
            <Dialog header={editModule == null ? "Add Module" : "Update Module"} visible={showAddEditModuleDialog} onHide={() => onHide("showAddEditModuleDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                <AddEditModule permissions={permissions} editData={editModule} onHide={() => onHide("showAddEditModuleDialog")} />
            </Dialog>
            <Dialog header="Edit Status" visible={showAddEditStatusModuleDialog} onHide={() => onHide("showAddEditStatusModuleDialog")} style={{ width: "40vw" }}>
                <StatusChange permissions={permissions} editData={editModule} onHide={() => onHide("showAddEditStatusModuleDialog")} />
            </Dialog>
            <Dialog header={editSubModule == null ? "Add Sub-Module" : "Update Sub-Module"} visible={showAddEditSubModuleDialog} onHide={() => onHide("showAddEditSubModuleDialog")} breakpoints={{ "960px": "75vw", "640px": "95vw" }} style={{ width: "50vw" }}>
                <AddEditSubModule permissions={permissions} editData={editSubModule} onHide={() => onHide("showAddEditSubModuleDialog")} addSubSuccess={addSubSuccess} />
            </Dialog>
            <Dialog header="Edit Status" visible={showAddEditStatusSubModuleDialog} onHide={() => onHide("showAddEditStatusSubModuleDialog")} style={{ width: "40vw" }}>
                <SubModuleStatusChange permissions={permissions} editData={editSubModule} onHide={() => onHide("showAddEditStatusSubModuleDialog")} />
            </Dialog>
            <div className="card">
                <div className="grid pb-3">
                    <div className="col-12 md:col-6">
                        <h4>Modules</h4>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="mb-9 text-right">
                            <span className="p-input-icon-right">
                                {permissions?.add && (
                                    <Button
                                        label="Add Module"
                                        type="submit"
                                        className="mr-3 p-button-success p-button-rounded p-button-sm custom___width mb_width"
                                        onClick={() => {
                                            setEditModule(null);
                                            onClick("showAddEditModuleDialog");
                                        }}
                                    />
                                )}
                            </span>
                        </div>
                    </div>
                </div>
                <DataTable
                    filter
                    expandedRows={expandedRows}
                    rows={50}
                    paginator
                    rowClassName={rowClass}
                    value={data}
                    responsiveLayout="scroll"
                    onRowToggle={(e) => {
                        setExpandedRows(e.data);
                    }}
                    emptyMessage="No record found."
                    rowExpansionTemplate={expandedTableTemp}
                >
                    <Column className="maker_colm" expander={allowExpansion} style={{ width: "3em" }} />

                    <Column className="maker_colm" style={{ minWidth: "22rem" }} field="name" body={nameBodyTemp} header="Name" />
                    <Column className="maker_colm" style={{ minWidth: "22rem" }} body={iconBodyTemp} header="Icons" />
                    {/* <Column className="maker_colm text-center" style={{ minWidth: "2rem" }} header="Status" body={statusBodyTemplate} /> */}
                    <Column className="maker_colm" body={actionTemplate} header="Actions" />
                </DataTable>
            </div>
        </>
    );
};

export default Module;
