/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useDispatch, useSelector } from "react-redux";
import { deleteVehicleMake, getVehicleMakers, resetAddEditVehicleMakers } from "../../../../../redux/slices/vehicle_makers_slice";
import CustomLoading from "../../../components/custom_loading";
import VMakerDialoge from "../../vehiclemanagement/components/vehicle-maker-dailog";

export const vehicleMakers = ({ permissions }) => {
    //redux
    const { data: list, loading, deleteMakeSuccess, deleteError } = useSelector((state) => state.vehicleMakers);
    const dispatch = useDispatch();
    const [editUser, setEditUser] = useState(null);
    const [AddMaker, setAddMaker] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    useEffect(() => {
        if (list === undefined) {
            dispatch(getVehicleMakers());
        }
    }, []);
    // dialog
    const dialogFuncMap = {
        AddMaker: setAddMaker,
    };
    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    };
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };

    let deleteId;
    const accept = () => {
        let data = {
            makeId: deleteId,
        };
        dispatch(deleteVehicleMake(data));
    };
    const confirmDeleteAd = () => {
        confirmDialog({
            message: "Do you want to delete this vehicle maker?",
            header: "Delete Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept,
        });
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="">
                {permissions?.edit && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditUser(rowData);
                            onClick("AddMaker");
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
                {permissions?.delete && (
                    <Button
                        tooltip="Delete"
                        icon="pi pi-trash"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            deleteId = rowData._id;
                            confirmDeleteAd();
                        }}
                        className="edit mr-2 p-button-danger p-button-rounded"
                    />
                )}
            </div>
        );
    };

    const makeTemp = (rowData) => {
        // const capital = rowData.make.toUpperCase();
        const capital = rowData.make.charAt(0).toUpperCase() + rowData.make.slice(1).toLowerCase();
        return <div>{capital}</div>;
    };
    useEffect(() => {
        if (deleteMakeSuccess !== undefined) {
            if (deleteMakeSuccess === true) {
                toast.success("Vehicle Maker deleted");
                dispatch(getVehicleMakers());
            } else {
                toast.error(deleteError);
            }
        }
        return () => {
            dispatch(resetAddEditVehicleMakers());
        };
    }, [deleteMakeSuccess, deleteError]);

    return (
        <>
            {loading === true || list === undefined ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <Dialog header={editUser == null ? "Add Vehicle Makers" : "Update Vehicles Makers"} visible={AddMaker} onHide={() => onHide("AddMaker")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "70vw" }} className="Modal_class">
                        <VMakerDialoge editUser={editUser} onHide={() => onHide("AddMaker")} />
                    </Dialog>
                    <div className="grid pb-3">
                        <div className="col-12 md:col-6">
                            <h4>Vehicle maker</h4>
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="mb-9 text-right">
                                <span className="p-input-icon-right mr-3">
                                    <InputText className="mbl_view" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                                </span>
                                {permissions?.add && (
                                    <Button
                                        style={{ fontSize: "14px" }}
                                        label="Add new"
                                        className="p-button-success p-button-rounded p-button-sm custom___width"
                                        onClick={() => {
                                            setEditUser(null);
                                            onClick("AddMaker");
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <DataTable className="p-datatable-gridlines" filters={filters} rows={50} paginator responsiveLayout="scroll" emptyMessage="No record found." value={list} globalFilterFields={["make"]}>
                        <Column body={makeTemp} className="maker_colm" header="Vehicle Maker" />
                        {/* <Column body={activeStatusBody} header="Vehicle Type" /> */}
                        <Column header="Action" body={actionTemplate} className="text-center Action_col" />
                    </DataTable>
                </div>
            )}
        </>
    );
};
