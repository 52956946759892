import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCorporateCodesList } from "../../../../../redux/slices/corporate_code_slice";
import AddCorporateCodeDialog from "../componenets/add_corporate_code_dialog";
import { InputText } from "primereact/inputtext";
import CustomPageHeader from "../../../components/custom_page_header";
import { Paginator } from "primereact/paginator";
import AddCorporateDialog from "../componenets/AddCorporateDialog";
import AddCorporateAdminDialog from "../componenets/AddCorporateAdminDialog";
import CustomLoading from "../../../components/custom_loading";
import appUrl from "../../../constants/appUrl";
import { Dropdown } from "primereact/dropdown";
export default function CorporateCodeScreen({ permissions }) {
    const dispatch = useDispatch();
    //hooks
    const [editCode, setEditCode] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(50);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [showCorporateDialog, setShowCorporateDialog] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [corporateData, setCorporateData] = useState();
    const [editable, setEditable] = useState(false);
    const [corporateModal, setCorporateModal] = useState(false);
    const [statusFilter, setStatusFilter] = useState(""); // '' for no filter, 'active' or 'inactive' for filtering

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        setGlobalFilterValue(value);
    };

    const { data: list, loading, count } = useSelector((state) => state.corporateCode);

    const clearFilters = () => {
        // Clear formik values
        setStatusFilter("");
        setGlobalFilterValue("");
        setPageNumber(0);
        setFirst(0);
        dispatch(
            getCorporateCodesList({
                pageNumber: 0,
                text: "",
                perPageRecords: rows,
                gender: "",
                loginDevice: "",
                status: "",
                corporate: "",
            })
        );
    };

    const rowClass = (data) => {
        if (data?.data?.active === true) {
            return {
                "bg-success": true, // Green background for active
            };
        } else if (data?.data?.active === false) {
            return {
                "bg-danger": true, // Red background for inactive
            };
        } else {
            return {}; // No class if no status
        }
    };

    useEffect(() => {
        if (list === undefined) {
            dispatch(
                getCorporateCodesList({
                    pageNumber,
                    text: globalFilterValue,
                    perPageRecords: rows,
                })
            );
        }
    }, []);
    useEffect(() => {
        setPageNumber(0);
        setFirst(0);
        dispatch(
            getCorporateCodesList({
                pageNumber,
                text: globalFilterValue,
                perPageRecords: rows,
            })
        );
    }, [globalFilterValue]);
    const [showAddDialog, setAddDialog] = useState(false);
    const tableData = list?.map((item) => {
        const logo = item?.corporteData?.logo ?? "No logo"; // If no logo, display "No logo"
        return {
            key: item?.corporteData?._id,
            data: {
                name: item?.corporteData?.name,
                address: item?.corporteData?.address,
                active: item?.corporteData?.active,
                domains: item?.corporteData?.domains,
                logo: item?.corporteData?.logo,
            },

            children: item?.coporateCodes?.map((code) => {
                return {
                    key: code?._id,
                    data: {
                        title: code?.title,
                        code: code?.code,
                        fee: code?.fee,
                        active: code?.active,
                        corporate: item?.corporteData?._id,
                        currencyId: code?.currency?._id,
                        currencyCode: code?.currency?.currencyCode,
                    },
                };
            }),
        };
    });
    const filteredData = tableData?.filter((item) => {
        const matchesStatusFilter = statusFilter === "" || (statusFilter === "active" && item.data.active === true) || (statusFilter === "inactive" && item.data.active === false);

        const matchesGlobalFilter = globalFilterValue === "" || item.data.name.toLowerCase().includes(globalFilterValue.toLowerCase());

        return matchesStatusFilter && matchesGlobalFilter;
    });
    const dialogFuncMap = {
        showAddDialog: setAddDialog,
    };
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    };
    const feeBodyTemplate = (rowData) => {
        return <span className={rowData?.fee > 0 ? "green font-bold" : "red font-bold"}>{typeof rowData?.fee === "number" ? (rowData?.fee > 0 ? rowData?.fee : rowData?.fee * -1) : 0}</span>;
    };
    const statusBodyTemplate = (rowData) => {
        return <div className={rowData?.data?.active === true ? "bg_green" : "bg_red"}>{rowData?.data?.active === true ? "Active" : "Inactive"}</div>;
    };
    const actionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setCorporateData(rowData);
                            setShowCorporateDialog(true);
                            setEditable(true);
                        }}
                        className="edit mr-2 p-button-success p-button-rounded"
                    />
                )}
            </div>
        );
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setPageNumber(event.first / event.rows);
        setRows(event.rows);
        dispatch(
            getCorporateCodesList({
                pageNumber: event.first / event.rows,
                text: globalFilterValue,
                perPageRecords: event.rows,
            })
        );
    };

    const onHideCorporateDialog = () => {
        setShowCorporateDialog(false);
    };
    const emptyMessageCenter2 = () => {
        return (
            <div className="p-col-12" style={{ textAlign: "center" }}>
                No code added yet.
            </div>
        );
    };

    const statusTemplate = (rowData) => {
        return (
            <Button className={rowData?.data?.active === true ? "bg_green ml-2" : "bg_red ml-2"} style={{ border: "none", boxShadow: "none" }}>
                {rowData?.data?.active === true ? "Active" : "Inactive"}
            </Button>
        );
    };

    const feeTemplate = (rowData) => {
        return <span className={rowData?.data?.fee > 0 ? "red font-bold ml-3" : "red font-bold ml-3"}>{rowData?.data?.fee}</span>;
    };

    const subActionTemplate = (rowData) => {
        return (
            <div className="Edit_Icon">
                {permissions?.view && (
                    <Button
                        tooltip="View Details"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: "top" }}
                        onClick={() => {
                            setEditCode(rowData);
                            onClick("showAddDialog");
                        }}
                        className="edit ml-3 p-button-success p-button-rounded"
                    />
                )}
            </div>
        );
    };
    const expandableRowTemplate = (rowData) => {
        const subTableData = rowData?.children;
        return (
            <div className="mt-3 mb-4 pb-3 pt-3" style={{ background: "#f8f9fa", borderRadius: "10px" }}>
                <h5 style={{ display: "flex", justifyContent: "center", fontWeight: "600" }}> {rowData?.title} Corporate Code Details </h5>
                <DataTable rowClassName={rowClass} value={subTableData} emptyMessage={emptyMessageCenter2}>
                    <Column className="maker_colm" field="data.title" header="Name" />
                    <Column className="maker_colm" field="data.code" header="Code" />
                    <Column className="maker_colm" body={(rowData) => feeTemplate(rowData)} header="Fee" />
                    <Column className="maker_colm" field="data.currencyCode" header="Currency Code" />
                    {/* <Column className="maker_colm" body={(rowData) => statusTemplate(rowData)} header="Status" /> */}
                    <Column className="maker_colm" body={(rowData) => subActionTemplate(rowData)} header="Actions" />
                </DataTable>
            </div>
        );
    };

    const onRowToggle = (event) => {
        setExpandedRows(event.data);
    };

    const onHideCorporateAdminDialog = () => {
        setCorporateModal(false);
    };

    const codeTemplate = (rowData) => {
        return <p className="ml-5">{rowData?.children?.length}</p>;
    };

    return (
        <>
            {!list ? (
                <CustomLoading />
            ) : (
                <div className="card">
                    <Dialog header={editCode == null ? "Add Corporate Code" : "Update Corporate Code"} visible={showAddDialog} onHide={() => onHide("showAddDialog")} breakpoints={{ "960px": "75vw", "640px": "100vw" }} style={{ width: "50vw" }}>
                        <AddCorporateCodeDialog tableData={tableData} permissions={permissions} editCode={editCode} onHide={() => onHide("showAddDialog")} getCorporateCodesList={getCorporateCodesList} />
                    </Dialog>
                    <Dialog style={{ width: "75vw" }} header={editable == false ? "Add Corporate" : "Update Corporate"} visible={showCorporateDialog} onHide={onHideCorporateDialog}>
                        <AddCorporateDialog onHideCorporateDialog={onHideCorporateDialog} corporateData={corporateData} editable={editable} getCorporateCodesList={getCorporateCodesList} />
                    </Dialog>
                    <Dialog style={{ width: "60vw" }} header={editCode == null ? "Add Corporate Admin" : "Update Corporate Admin"} visible={corporateModal} onHide={onHideCorporateAdminDialog}>
                        <AddCorporateAdminDialog onHideCorporateAdminDialog={onHideCorporateAdminDialog} />
                    </Dialog>
                    <div className="flex justify-content-between flex-wrap mb-3">
                        <div>
                            <CustomPageHeader title={"Corporates"} />
                        </div>
                        <div className="text-right flex flex-wrap">
                            <div className="p-grid">
                                <div className="p-col">
                                    <Button style={{ verticalAlign: "top", fontSize: "14px" }} label="Clear Filters" className=" mr-3 text-left" onClick={clearFilters} />
                                    <Dropdown
                                        style={{ width: "120px" }}
                                        value={statusFilter}
                                        options={[
                                            { label: "Active", value: "active" },
                                            { label: "Inactive", value: "inactive" },
                                        ]}
                                        onChange={(e) => setStatusFilter(e.value)}
                                        placeholder="Status"
                                    />
                                </div>
                            </div>
                            <span className="p-input-icon-right mx-3">
                                <InputText style={{ width: "140px" }} className="mbl_mrb" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                            </span>
                            <Button onClick={() => setCorporateModal(true)} style={{ width: "auto", fontSize: "12px" }} label="Add Corporate User" className="p-button-success ml-2 p-button-rounded p-button-sm custom___width" />
                            <Button
                                onClick={() => {
                                    // setEditCode(null);
                                    setEditable(false);
                                    setShowCorporateDialog(true);
                                }}
                                style={{ width: "auto", fontSize: "12px" }}
                                label="Add Corporate"
                                className="p-button-success ml-2 p-button-rounded p-button-sm custom___width"
                            />
                            <Button
                                onClick={() => {
                                    setEditCode(null);
                                    onClick("showAddDialog");
                                }}
                                style={{ width: "auto", fontSize: "12px" }}
                                label="Add Code"
                                className="p-button-success ml-2 p-button-rounded p-button-sm custom___width"
                            />
                        </div>
                    </div>
                    <DataTable rowClassName={rowClass} value={filteredData} expandedRows={expandedRows} onRowToggle={onRowToggle} rowExpansionTemplate={expandableRowTemplate}>
                        <Column className="maker_colm" expander style={{ width: "3em" }} header="" />
                        <Column className="maker_colm" field="data.logo" header="Logo" body={(rowData) => <img src={rowData?.data?.logo ? `${appUrl?.baseUrl}/${rowData.data.logo}` : "https://via.placeholder.com/50"} alt="logo" style={{ width: "50px", height: "50px", objectFit: "cover" }} />} />
                        <Column className="maker_colm" field="data.name" header="Name" />
                        <Column className="maker_colm" field="data.address" header="Address" />
                        <Column className="maker_colm" body={(rowData) => codeTemplate(rowData)} header="No of Codes" />
                        {/* <Column className="maker_colm" field="data.status" header="Status" body={statusBodyTemplate} /> */}
                        <Column className="maker_colm" body={actionTemplate} header="Action" />
                    </DataTable>
                    <Paginator first={first} rows={rows} totalRecords={count} onPageChange={onPageChange} rowsPerPageOptions={[10, 20, 30, 50]} />
                </div>
            )}
        </>
    );
}
