import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInputField from "../../../components/custom_input_filed";
import * as Yup from "yup";
import { Button } from "primereact/button";
import CustomLoading from "../../../components/custom_loading";
import { toast } from "react-toastify";
import { getConfigurations, resetUpdateConfigurations, updateConfigurations } from "../../../../../redux/slices/configuration_slice";

export default function RahperShareScreen({ permissions }) {
    //redux
    const configurationReducer = useSelector((state) => state.configuration);
    const { data: configurationsData, updateData, loading, updateSuccess, updateError, updateLoading } = configurationReducer;

    const [isChanged, setIsChanged] = useState(false);
    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        rahperShare: Yup.number()
            .integer("Rahper Share must be an integer")
            .min(0, "Rahper Share cannot be less than 0")
            .max(100, "Rahper Share cannot be more than 100")
            .required("Rahper Share is required"),
    });

    const formik = useFormik({

        validationSchema: validationSchema,
        initialValues: {
            rahperShare: 0,

        },

        onSubmit: async (data) => {
            setIsChanged(true)
            const ridePointObj = configurationsData?.ridePoint?.map((data) => {
                const obj = {
                    point: data?.point,
                    price: data?.price
                }
                return obj;
            })
            const payload = {
                rahperShare:data?.rahperShare || 0,
                redeemValue: configurationsData?.redeemValue || 0,
                referPoint: configurationsData?.referPoint || 0,
                ridePoint: ridePointObj
            };
            dispatch(updateConfigurations(payload))
            if (isChanged) {
                dispatch(getConfigurations());
            }
            setIsChanged(false)
        },
    });
    //useEffects
    useEffect(() => {
        if (configurationsData === undefined) {
            dispatch(getConfigurations());
        }
        return () => {
            dispatch(resetUpdateConfigurations());
        };
    }, [dispatch, configurationsData]);

    useEffect(() => {
        if (configurationsData !== undefined) {
            formik.setFieldValue("rahperShare", configurationsData?.rahperShare);
        }
    }, [configurationsData]);
    useEffect(() => {
        const hasChanges = () => {
            const normalize = (value) => (value !== undefined && value !== null ? value.toString().trim() : "");

            return (
                normalize(formik.values.rahperShare) !== normalize(configurationsData?.rahperShare)
            );
        };

        setIsChanged(hasChanges());
    }, [formik.values, configurationsData]);

    useEffect(() => {
        if (updateSuccess !== undefined) {
            if (updateSuccess === true) {
                toast.success("Updated successfully!");
            } else {
                toast.error(updateError);
            }
        }
    }, [updateSuccess]);
    return (
        <>
            {loading === true ? (
                <CustomLoading />
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid p-fluid">
                        <div className="col-12">{/* <h5>Version Controls</h5> */}</div>
                        <CustomInputField iden="rahperShare" formik={formik} label="Rahper Share" type="number" className="field col-12 md:col-4" />
                        <div className="field col-12 md:col-4"></div>
                        <div className="field col-12 md:col-4"></div>
                    </div>
                    <div className="flex justify-content-center">
                        <div className="text-right">
                            {/* {permissions?.edit &&  //col-12 md:col-5 */}
                            <Button
                                // loading={updateLoading} 
                                disabled={!isChanged}
                                label="Update" type="submit" className="p-button-success p-button-rounded p-button-sm custom___width" />
                            {/* }  */}
                        </div>
                    </div>
                </form>
            )}
        </>
    );
}
