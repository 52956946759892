import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomInputField from "../../../components/custom_input_filed";
import * as Yup from "yup";
import { Button } from "primereact/button";
import CustomLoading from "../../../components/custom_loading";
import { toast } from "react-toastify";
import { getConfigurations, resetUpdateConfigurations, updateConfigurations } from "../../../../../redux/slices/configuration_slice";
import { TabPanel, TabView } from "primereact/tabview";
import PerReferenceScreen from "../components/per_reference_screen";
import RedeemValueScreen from "../components/redeem_value_screen";
import RahperShareScreen from "../components/rahper_share_screen";

export default function ConfigurationsScreen({ permissions }) {
   
    return (
        <>
            <div className="card">
                <div className=" pb-5">
                    <div className="grid">
                        <div className="col-12 md:col-7">
                            <h4>Configurations Management</h4>
                        </div>
                    </div>
                </div>
                <TabView>
                    <TabPanel header="Points">
                        <PerReferenceScreen />
                    </TabPanel>
                    <TabPanel header="Redeem Value">
                        <RedeemValueScreen />
                        {/* <AllRidesComponents permissions={permissions} flag={flag} userType="2" /> */}
                    </TabPanel>
                    <TabPanel header="Rahper Share">
                        <RahperShareScreen />
                    </TabPanel>
                </TabView>
            </div>
        </>
    );
}
